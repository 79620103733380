var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "faultList"
  }, [_c('div', {
    staticClass: "operator"
  }, [_c('a-space', [_c('span', [_vm._v("共 " + _vm._s(_vm.total) + " 条记录")]), _c('a-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.exportFault
    }
  }, [_vm._v("导出数据"), _c('a-icon', {
    attrs: {
      "type": "download"
    }
  })], 1)], 1), _c('a-select', {
    staticClass: "operator_select",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "placeholder": "错误状态",
      "allowClear": ""
    },
    on: {
      "change": function change(e) {
        return _vm.onSearchChange(e);
      }
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(" 已修复 ")]), _c('a-select-option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(" 未修复 ")])], 1), _c('a-range-picker', {
    staticClass: "operator_date",
    attrs: {
      "format": _vm.dateFormatShort,
      "default-value": [_vm.moment(_vm.stime, _vm.dateFormatShort), _vm.moment(_vm.etime, _vm.dateFormatShort)],
      "ranges": {
        今天: [_vm.moment(), _vm.moment()],
        本周: [_vm.moment().startOf('week'), _vm.moment()],
        本月: [_vm.moment().startOf('month'), _vm.moment()],
        今年: [_vm.moment().startOf('year'), _vm.moment()]
      }
    },
    on: {
      "change": _vm.onDateChange
    }
  })], 1), _c('standard-table', {
    attrs: {
      "columns": _vm.columns,
      "dataSource": _vm.dataSource,
      "rowKey": "errLogId",
      "pagination": {
        total: _vm.total,
        pageSize: _vm.pagination.pageSize
      }
    },
    on: {
      "clear": _vm.onClear,
      "change": _vm.onChange,
      "selectedRowChange": _vm.onSelectChange
    },
    scopedSlots: _vm._u([{
      key: "description",
      fn: function fn(_ref) {
        var text = _ref.text;
        return _c('div', {}, [_vm._v(" " + _vm._s(text) + " ")]);
      }
    }, {
      key: "status",
      fn: function fn(_ref2) {
        var record = _ref2.record;
        return _c('div', {}, [record.status === '1' ? _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v("未修复")]) : _c('span', {
          staticStyle: {
            "color": "green"
          }
        }, [_vm._v(" 已修复")])]);
      }
    }, {
      key: "action",
      fn: function fn(_ref3) {
        var record = _ref3.record;
        return _c('div', {}, [_c('a', {
          staticStyle: {
            "margin-right": "8px"
          }
        }, [_c('a-icon', {
          attrs: {
            "type": "edit"
          }
        }), _vm._v("编辑 ")], 1), _c('a', {
          on: {
            "click": function click($event) {
              return _vm.deleteRecord(record.key);
            }
          }
        }, [_c('a-icon', {
          attrs: {
            "type": "delete"
          }
        }), _vm._v("删除 ")], 1)]);
      }
    }])
  }, [_c('template', {
    slot: "statusTitle"
  }, [_c('a-icon', {
    attrs: {
      "type": "info-circle"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.onStatusTitleClick.apply(null, arguments);
      }
    }
  })], 1)], 2)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };