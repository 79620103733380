<template>
  <div class="log_table">
    <a-table
      :dataSource="searchData"
      :columns="tableColumns"
      :padding="[10, 0, 'auto', 0]"
      size="small"
      rowKey="key"
      :pagination="{
        position: 'bottom',
        style: { marginBottom: 0 },
        pageSize: pageSize,
        total: total,
      }"
      :loading="loading"
      @change="onChange"
    >
      <!-- <a href="#/" slot="keyword" slot-scope="text">{{text}}</a> -->
      <span slot="rang" slot-scope="text"
        >{{ text }} %<a-icon type="caret-up" />
      </span>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "日期",
    dataIndex: "date",
    align: "center",
    // key: 'rank'
  },
  {
    title: "处理量(kg)",
    dataIndex: "dlyIn",
    align: "center",
    // key: 'users',
    // sorter: (a, b) => a.count - b.count
  },
  {
    title: "园林垃圾处理量(kg)",
    dataIndex: "dlyPltWst",
    align: "center",
    // key: 'users',
    // sorter: (a, b) => a.count - b.count
  },
  {
    title: "产出物(kg)",
    dataIndex: "dlyOut",
    align: "center",
    customRender: (text, record) => {
      if (record.dlyOut !== null) {
        // return record.deviceInfos.dlyIn;
        return Math.round(record.dlyOut * 10) / 10;
      }
    },
    // key: 'users',
    // sorter: (a, b) => a.count - b.count
  },
  {
    title: "消耗电量(度)",
    dataIndex: "dn",
    align: "center",
    // key: 'range',
    // scopedSlots: {customRender: 'rang'}
  },
];

import { dataSource as ds } from "@/services";
import { format } from "date-fns";
export default {
  name: "LogTable",
  components: {},
  data() {
    return {
      searchData: [],
      columns,
      loading: true,
      total: 0,
      current: 1,
      pageSize: 6,
      stime: format(
        new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      ),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss"),
    };
  },
  props: ["id", "sn", "clw", "yjgf", "yllj"],
  computed: {
    tableColumns() {
      // let columns = this.columns;
      const columns = [
        {
          title: "日期",
          dataIndex: "date",
          align: "center",
          // key: 'rank'
        },
        {
          title: (this.clw || '有机固废') +  "处理量(kg)",
          dataIndex: "dlyIn",
          align: "center",
          disabled: this.yjgf !== true,
          // key: 'users',
          // sorter: (a, b) => a.count - b.count
        },
        {
          title: "园林垃圾处理量(kg)",
          dataIndex: "dlyPltWst",
          align: "center",
          disabled: this.yllj !== true,
          // key: 'users',
          // sorter: (a, b) => a.count - b.count
        },
        {
          title: "产出物(kg)",
          dataIndex: "dlyOut",
          align: "center",
          customRender: (text, record) => {
            if (record.dlyOut !== null) {
              // return record.deviceInfos.dlyIn;
              return Math.round(record.dlyOut * 10) / 10;
            }
          },
          // key: 'users',
          // sorter: (a, b) => a.count - b.count
        },
        {
          title: "消耗电量(度)",
          dataIndex: "dn",
          align: "center",
          // key: 'range',
          // scopedSlots: {customRender: 'rang'}
        },
      ];
      return columns.filter(i => i.disabled !== true);
      // return columns.filter(i => i.disabled !== true).map((item) => {
      //   item.title = this.$t(item.key);
      //   return item;
      // });
    },
  },
  methods: {
    update(sn = this.sn, stime = this.stime, etime = this.etime) {
      this.loading = true;
      console.log("LogTabel update");
      const { current, pageSize } = this;
      this.stime = stime;
      this.sn = sn;
      this.etime = etime;
      ds.deviceInfoDayList({
        deviceSn: sn,
        etime: etime,
        pageIndex: current,
        pageSize: pageSize,
        stime: stime,
        sort: 'desc',
      })
        .then((res) => {
          console.log("LogTabel deviceInfoDayList", res);
          if (res.data.meta.success) {
            let { list, total } = res.data.data.list;
            // this.pageIndex = pageNum;
            this.total = total ?? 0;
            // this.pageSize = pageSize;
            list.map((v, index) => {
              v.key = index;
              // v.dlyOut = Number(((v?.dlyOut || 0) + (v?.dlyPltWst || 0)).toFixed(2));   // 加上园林垃圾
            });
            if(list.length > this.pageSize) {
              this.searchData = list.slice(0, this.pageSize);
            } else {
              this.searchData = list;
            }
            console.log("LogTabel searchData", this.searchData);
          }
        })
        .catch((err) => {
          console.log("LogTabel deviceInfoDayListErr", err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("onChange", e);
      const { current } = e;
      this.current = current;
      this.update();
    },
  },
};
</script>

<style lang="less" scoped>
.log_table {
  // padding-bottom: 10px;
  height: 308px;
  background: rgba(0, 20, 74, 0.8);
  border: 1px solid #027cc3;
  // box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  :deep(.ant-table) {
    min-height: 260px;
  }
  :deep(.ant-empty-normal) {
    background-color: #fff0;
    color: #00f8fe;
  }

  :deep(.ant-table-placeholder) {
    background-color: #fff0;
    border-top-color: #fff2;
  }

  :deep(.ant-table-small) {
    // border: 1px solid #0169ad;
    border: none;
    .ant-table-thead {
      > tr {
        > th {
          color: #00f8fe;
          border-bottom: 1px solid #fff5;
        }
      }
    }
    .ant-table-tbody {
      > tr {
        > td {
          color: #fff;
          border-bottom: 1px solid #fff2;
        }
        &:hover {
          > td {
            background-color: #000000aa;
          }
        }
      }
    }
  }

  :deep(.ant-pagination) > {
    .ant-pagination-item a {
      color: #407fc6;
    }
  }
  :deep .ant-table-pagination { 
    &.ant-pagination {
      margin-top: 6px;
    }
  }
}
// :deep(.ant-table{
//   min-height: 260px;
// }
:deep(.ant-empty-normal) {
  min-height: 100px;
}
</style>
