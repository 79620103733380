import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import StandardTable from "@/components/table/StandardTable";
import { dataSource as ds } from "@/services";
import moment from "moment";
import { format } from "date-fns";
var columns = [{
  title: "日期",
  dataIndex: "updatetime"
}, {
  title: "报警代码",
  dataIndex: "errCode" // scopedSlots: { customRender: 'description' }

}, {
  title: "状态",
  // dataIndex: "status",
  scopedSlots: {
    customRender: 'status'
  } // customRender: record => {
  //   if (record === '1') {
  //     return "未修复";
  //   } else {
  //     return "已修复";
  //   }
  // },

}, {
  title: "代码内容",
  dataIndex: "errContent"
}];
export default {
  name: "FaultList",
  components: {
    StandardTable: StandardTable
  },
  props: ["id", "sn"],
  data: function data() {
    return {
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      dateFormatShort: "YYYY-MM-DD",
      monthFormat: "YYYY年MM月",
      advanced: true,
      showForm: false,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      total: 0,
      status: undefined,
      pagination: {
        current: 1,
        pageSize: 10
      },
      stime: format(new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24), "yyyy-MM-dd HH:mm:ss"),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss")
    };
  },
  // authorize: {
  //   deleteRecord: 'delete'
  // },
  mounted: function mounted() {},
  methods: {
    moment: moment,
    update: function update() {
      var _this = this;

      ds.deviceErrorList({
        pageIndex: this.pagination.current < 1 ? 1 : this.pagination.current,
        pageSize: this.pagination.pageSize,
        deviceSn: this.sn,
        etime: this.etime,
        stime: this.stime,
        status: this.status,
        all: 0
      }).then(function (res) {
        _this.dataSource = res.data.data.list.list;
        _this.total = res.data.data.list.total;
        _this.pagination.current = res.data.data.list.pageNum;
      }).catch(function (err) {});
    },
    deleteRecord: function deleteRecord(key) {
      this.dataSource = this.dataSource.filter(function (item) {
        return item.key !== key;
      });
      this.selectedRows = this.selectedRows.filter(function (item) {
        return item.key !== key;
      });
    },
    toggleAdvanced: function toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove: function remove() {
      var _this2 = this;

      this.dataSource = this.dataSource.filter(function (item) {
        return _this2.selectedRows.findIndex(function (row) {
          return row.key === item.key;
        }) === -1;
      });
      this.selectedRows = [];
    },
    onClear: function onClear() {
      this.$message.info("您清空了勾选的所有行");
    },
    onStatusTitleClick: function onStatusTitleClick() {
      this.$message.info("你点击了状态栏表头");
    },
    onChange: function onChange(e) {
      // this.$message.info('表格状态改变了')
      var current = e.current;
      this.pagination.current = current;
      this.update();
    },
    onSearchChange: function onSearchChange(e) {
      this.status = e;
      this.update();
    },
    onSelectChange: function onSelectChange() {
      this.$message.info("选中行改变了");
    },
    exportFault: function exportFault() {
      this.$message.info("正在导出...");
      ds.errorExport({
        deviceSn: this.sn,
        etime: this.etime,
        stime: this.stime,
        status: this.status
      }).then(function (res) {
        var fileName = res.headers["content-disposition"].split(";")[1].split("=")[1].split(".xlsx")[0]; // 根据接口返回情况拿到文件名

        var blob = new Blob([res.data], {
          type: res.headers["content-type"]
        }); // 通过返回的流数据 手动构建blob 流
        // const blob = new Blob([res.data], {type: "application/vnd.ms-excel;charset=utf-8"});

        var reader = new FileReader();
        var url = window.URL.createObjectURL(blob); // 创建新的url并指向file对象或blob对象的地址

        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签的href

        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = decodeURIComponent(fileName) + ".xlsx"; // 构建 下载的文件名称以及下载的文件格式（可通过传值输入）

          if (typeof e.target.result === "string") {
            a.href = e.target.result;
          }

          a.click();
          a.remove();
          window.URL.revokeObjectURL(url); // 释放内存
        };
      }).catch(function (err) {});
    },
    onDateChange: function onDateChange(date, dateString) {
      var time_now = format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss");
      this.stime = dateString[0] ? dateString[0] + time_now.substr(10) : "2000-01-01 00:00:00";
      this.etime = dateString[1] ? dateString[1] + time_now.substr(10) : format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss");

      if (dateString[0] === dateString[1] && dateString[0] !== "") {
        this.stime = this.stime.substr(0, 10) + " 00:00:00";
        this.etime = this.etime.substr(0, 10) + " 23:59:59";
      }

      this.update();
    },
    onDateClear: function onDateClear() {
      this.$message.info("onDateClear");
      this.stime = "2000-01-01 00:00:00";
      this.etime = format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss");
      this.update();
    },
    handleMenuClick: function handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    }
  }
};