<template>
  <div class="temp_chart">
    <h4>{{ title }}</h4>
    <a-spin :spinning="loading">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      <div class="chart">
        <!-- <div class="chart_empty" v-if="empty">
          {{ emptytext ? emptytext : "暂无数据" }}
        </div> -->
        <div  ref="rtcharts" :style="`height: ${height}px`"></div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
import { dataSource as ds } from "@/services";
import { format } from "date-fns";
export default {
  name: "TempChart",
  props: ["title", "height"],
  data() {
    return {
      empty: true,
      loading: false,
      sourceData: [],
      option: null,
      myChart: null,
      resizeHandle: null,
      emptytext: null,
      chartWidth: 0,
      label: {
        // offset: 8,
        textStyle: {
          fill: "#fff",
          fontSize: 12,
        },
      },
      style: { stroke: "#fff", lineWidth: 1 },
      stime: format(
        new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      ),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss"),
    };
  },
  computed: {},
  mounted() {
    this.resizeHandle = debounceTail(() => this.resize(), 299, this);
    window.addEventListener("resize", this.resizeHandle);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    resize() {
      if (this.myChart) {
        this.myChart.resize();
        let newWidth = this.myChart.getWidth();
        if (this.chartWidth !== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    dispatchBrush(flag = true) {
      if (flag) {
        this.myChart?.dispatchAction({
          type: "takeGlobalCursor",
          // 如果想变为“可刷选状态”，必须设置。不设置则会关闭“可刷选状态”。
          key: "brush",
          brushOption: {
            id: "sele",
            // 参见 brush 组件的 brushType。如果设置为 false 则关闭“可刷选状态”。
            brushType: "lineX",
            // 参见 brush 组件的 brushMode。如果不设置，则取 brush 组件的 brushMode 设置。
            // brushMode: string
          },
        });
      } else {
        this.myChart?.dispatchAction({
          type: "takeGlobalCursor",
        });
        this.myChart?.dispatchAction({
          type: "brush",
          areas: [],
        });
      }
    },
    init_chart(v) {
      // console.log("init_chart", v);
      // 基于准备好的dom，初始化echarts实例
      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.rtcharts);
        this.myChart.on("brushSelected", (params) => {
          console.log("brushSelected", params);
          if (params.batch[0].areas.length === 0) {
            this.$emit("rangeSelect", null);
          } else {
            let d1 = params.batch[0].areas[0].coordRange[0];
            let d2 = params.batch[0].areas[0].coordRange[1];
            // console.log("brushSelected d", this.sourceData, this.sourceData[d1], this.sourceData[d2]);
            this.$emit("rangeSelect", [
              this.sourceData[d1].month,
              this.sourceData[d2].month,
            ]);
          }
        });
      }
      // 指定图表的配置项和数据
      this.option = {
        // title: {
        //   text: '实时数据',
        //   textStyle: {
        //     color: '#fff'
        //   }
        // },
        tooltip: {
          trigger: "axis",
          // trigger: "items",
        },
        legend: {
          top: 0,
          right: "10%",
          data: ["环境湿度(%)", "环境温度(°C)", "发酵温度(°C)"],
          itemHeight: 8,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
          icon: "circle",
        },
        grid: {
          top: 30,
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: v.map((i) => i.month),
          axisLabel: {
            color: "#fff",
            formatter: function formatter(text) {
              return text.replace("_", "\n");
            },
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: [
          {
            type: "value",
            max: 100,
            min: 0,
            name: "温度",
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
              },
            },
            nameTextStyle: {
              color: "#fff",
            },
            // axisLine: {
            //   show: true,
            // },
            axisLabel: {
              color: "#fff",
              // formatter: "{value}",
            },
          },
          {
            type: "value",
            max: 100,
            min: 0,
            name: "湿度",
            position: "right",
            splitLine: {
              show: false,
              lineStyle: {
                opacity: 0.2,
              },
            },
            nameTextStyle: {
              color: "#fff",
            },
            axisLabel: {
              color: "#4f6abf",
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            // showDataShadow: false,
          },
        ],
        // toolbox: {
        //   top: 'top',
        //   right: 'center',
        //   feature: {
        //     dataZoom: {
        //       yAxisIndex: 'none',
        //       title: {
        //         zoom: '区域缩放',
        //         back: '区域缩放还原'
        //       }
        //     },
        //     // magicType: { type: ['line', 'bar'] },
        //     // restore: {},
        //   }
        // },
        brush: {
          toolbox: [""],
          // xAxisIndex: 0,
          throttleDelay: 277,
          brushType: "lineX",
          throttleType: "debounce",
          xAxisIndex: 0,
          outOfBrush: {
            colorAlpha: 0.1,
          },
        },
        series: [
          {
            name: "环境湿度(%)",
            type: "line",
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              width: 1.5,
            },
            yAxisIndex: 1,
            // areaStyle: {
            //   color: {
            //     type: "radial",
            //     x: 0.5,
            //     y: 0.5,
            //     r: 1,
            //     colorStops: [
            //       {
            //         offset: 0,
            //         color: "#0344bc", // 0% 处的颜色
            //       },
            //       {
            //         offset: 0.8,
            //         color: "#061d76", // 100% 处的颜色
            //       },
            //     ],
            //     global: false, // 缺省为 false
            //   },
            //   opacity: 0.3,
            //   origin: "start",
            // },
            data: v.map((i) => i.envHum),
          },
          {
            name: "环境温度(°C)",
            type: "line",
            symbolSize: 0,
            smooth: true,
            data: v.map((i) => i.envTem),
          },
          {
            name: "发酵温度(°C)",
            type: "line",
            symbolSize: 0,
            smooth: true,
            data: v.map((i) => i.ferTem),
          },
        ],
      };
      this.myChart.setOption(this.option);
      // this.myChart.on("click", function(params) {
      //   console.log("click", params);
      // });
    },
    chart_clear() {
      this.ectime = [];
      this.ecdata1 = [];
      this.ecdata2 = [];
      this.ecdata3 = [];
    },
    chart_update(data) {
      console.log("chart_update", data);
      this.empty = false;
      this.option.xAxis.data.push(data.month);
      if (this.option.xAxis.data.length >= 60) {
        this.option.xAxis.data.shift();
      }
      this.option.series[0].data.push(data.envHum);
      if (this.option.series[0].data.length >= 60) {
        this.option.series[0].data.shift();
      }
      this.option.series[1].data.push(data.envTem);
      if (this.option.series[1].data.length >= 60) {
        this.option.series[1].data.shift();
      }
      this.option.series[2].data.push(data.ferTem);
      if (this.option.series[2].data.length >= 60) {
        this.option.series[2].data.shift();
      }
      this.myChart.setOption(this.option);
      // this.ectime.push(data.time);
      // if (this.ectime.length >= 8) {
      //   this.ectime.shift();
      // }
      // this.ecdata1.push(data.sd);
      // if (this.ecdata1.length >= 8) {
      //   this.ecdata1.shift();
      // }
      // this.ecdata2.push(data.wd);
      // if (this.ecdata2.length >= 8) {
      //   this.ecdata2.shift();
      // }
      // this.ecdata3.push(data.wdf);
      // if (this.ecdata3.length >= 8) {
      //   this.ecdata3.shift();
      // }
      // this.myChart.setOption({
      //   xAxis: {
      //     data: this.ectime,
      //   },
      //   series: [
      //     {
      //       data: this.ecdata1,
      //     },
      //     {
      //       data: this.ecdata2,
      //     },
      //     {
      //       data: this.ecdata3,
      //     },
      //   ],
      // });
    },
    update(
      sn = this.sn,
      stime = this.stime,
      etime = this.etime,
      realtime = true
    ) {
      this.loading = true;
      console.log("TempChart update");
      let fn = realtime ? ds.getDeviceInfos : ds.getDeviceInfosByDate;
      return new Promise((resolve, reject) => {
        fn({
          deviceSn: sn,
          etime: etime,
          stime: stime,
          num: 60,
        })
          .then((res) => {
            console.log("TempChart getDeviceInfos", res);
            this.empty = true;
            this.sourceData = [];
            if (res.data.meta.success) {
              let list = res.data.data.list.map((i) => {
                // let time =  `${time.substr(0,10)} ${time.substr(11,8)}`;
                let time =
                  i.createtime.substr(0, 10) + "_" + i.createtime.substr(11, 8);
                return {
                  month: time,
                  envTem: +i.envTem,
                  ferTem: +i.ferTem,
                  envHum: +i.envHum,
                };
              });
              if (list.length > 0) {
                this.sourceData = list;
                this.empty = false;
                this.emptytext = null;
                console.log("TempChart sourceData", list);
              } else {
                this.empty = true;
              }
            }
            this.$nextTick(() => {
              this.init_chart(this.sourceData);
            });
            this.loading = false;
            resolve();
          })
          .catch((err) => {
            this.loading = false;
            this.empty = true;
            this.emptytext = "获取数据出错";
            console.log("TempChart getDeviceInfosErr", err);
            reject();
          });
      });
    },
    pushdata(edata) {
      edata = JSON.parse(edata);
      console.log("pushdata", edata);
      if (edata.time === undefined) return;
      let time = format(new Date(new Date(edata.time)), "yyyy-MM-dd_HH:mm:ss");
      console.log("pushdata time", time);
      this.chart_update({
        month: time,
        envTem: edata.envTem,
        ferTem: edata.ferTem,
        envHum: edata.envHum,
      });
      // this.sourceData.push({
      //   month: time,
      //   '环境温度': edata.envTem,
      //   '发酵温度': edata.ferTem,
      //   '环境湿度': edata.envHum,
      // });
      // if(this.sourceData.length >= 60) {
      //   this.sourceData.shift();
      // }
      // console.log(this.vdata)
    },
  },
};
</script>

<style lang="less" scoped>
.temp_chart {
  position: relative;
  .chart {
    position: relative;
    .chart_empty {
      position: absolute;
      top: calc(50% - 22px);
      left: calc(50% - 39px);
      border: 1px solid #017ac1;
      background: #011448;
      padding: 10px;
      font-size: 14px;
      border-radius: 3px;
      z-index: 100;
    }
  }
}
</style>
