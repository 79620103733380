<template>
  <div class="optchart">
    <!-- <div class="title" @click="test">{{ title }}</div> -->
    <a-spin :spinning="loading">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      <div class="content" :style="`height: ${height}px`">
        <!-- <a-empty class="chart" v-if="empty" /> -->
        <div v-if="empty" class="chart_empty" >暂无数据</div>
        <div ref="main_chart" class="chart" :style="`height: ${height}px`"> </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
import { dataSource as ds } from "@/services";
import { format } from "date-fns";

export default {
  name: "OptChart",
  props: ["title", "height","sn","id"],
  data() {
    return {
      empty: true,
      loading: false,
      sourceData: [],
      option: null,
      myChart: null,
      resizeHandle: null,
      chartWidth: 0,
      stime: format(
        new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      ),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss"),
    };
  },
  mounted() {
    this.resizeHandle = debounceTail(() => this.resize(), 299, this);
    window.addEventListener(
      "resize",
      this.resizeHandle
    );
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeHandle)
  },
  methods: {
    resize() {
      if (this.myChart) {
        this.myChart.resize();
        let newWidth = this.myChart.getWidth();
        if(this.chartWidth!== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    init_chart(v) {
      const colors = ['#5470C6', '#91CC75', '#EE6666'];
      if (!this.myChart)
        this.myChart = echarts.init(this.$refs.main_chart);
      let lflag = v.length > 6 ? true : false;
      this.option = {
        title: {
          text: this.title,
          textStyle: {
            color: '#fff'
          }
        },
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
            // type: "shadow",
          }
        },
        grid: {
          bottom: lflag ? '25%' : '8%',
          left: '5%',
          right: '5%',
        },
        legend: {
          data: ['处理量(kg)', '产出物(kg)', '电量(度)'],
          right: '2%',
          textStyle: {
            color: '#fff'
          }
        },
        dataZoom: [
          {
            show: lflag,
            start: (1 - 6/v.length)*100,
            end: 100,
            maxValueSpan: 6,
          },
          {
            type: 'inside',
            disable: !lflag,
            end: 100,
            maxValueSpan: 6,
          }
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              color: '#fff',
              formatter: function (value) {
                return value.substr(5);
              }
            },
            // prettier-ignore
            data: v.map(i=>i.date)
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '处理量(kg)',
            position: 'left',
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '电量(度)',
            position: 'right',
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[2]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: '处理量(kg)',
            type: 'bar',
            barWidth: 20,
            data: v.map(i=>i.dlyIn)
          },
          {
            name: '产出物(kg)',
            type: 'bar',
            barWidth: 20,
            data: v.map(i=>i.dlyOut)
          },
          {
            name: '电量(度)',
            type: 'line',
            yAxisIndex: 1,
            data: v.map(i=>i.dn)
          }
        ]
      };
      // this.option.xAxis.data = v.map((i) => i.rank);
      // this.option.series[0].data = v.map((i) => i.num);
      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    },
    update(sn = this.sn, stime = this.stime, etime = this.etime) {
      this.loading = true;
      console.log("optchart update");
      ds.deviceInfoDayList({
        deviceSn: sn,
        etime: etime,
        pageIndex: 1,
        pageSize: 30,
        stime: stime,
        sort: 'desc',
      })
        .then((res) => {
          console.log("optchart deviceInfoDayList", res);
          if (res.data.meta.success) {
            let list = res.data.data.list.list;
            if(list.length > 0) {
              list.reverse();
              list.map((v) => {
                v.date = v.date.replace('-','年');
                v.date = v.date.replace('-','月');
                v.date+='日';
              });
              list.map((v, index) => {
                v.key = index;
                // v.dlyOut = Number(((v?.dlyOut || 0) + (v?.dlyPltWst || 0)).toFixed(2));   // 加上园林垃圾
                v.dlyIn = Number(((v?.dlyIn || 0) + (v?.dlyPltWst || 0)).toFixed(2));   // 加上园林垃圾
              });
              this.sourceData = list;
              this.empty = false;
            } else {
              this.sourceData = [];
              this.empty = true;
            }
          } else {
            this.sourceData = [];
            this.empty = true;
          }
          this.$nextTick(() => {
            this.init_chart(this.sourceData);
          })
        })
        .catch((err) => {
          console.log("optchart deviceInfoDayListErr", err);
          this.sourceData = [];
          this.empty = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    test() {
      // this.sourceData[0].sales+=10;
      this.update();
      // this.transformData();
      console.log("test", this.sourceData, this.vdata);
    },
  },
};
</script>

<style scoped lang="less">
.optchart {
  position: relative;
  .title {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .chart_empty {
    position: absolute;
    top: calc(50% - 22px);
    left: calc(50% - 39px);
    z-index: 10;
    border: 1px solid #017ac1;
    background: #011448;
    padding: 10px;
    font-size: 14px;
    border-radius: 3px;
  }
  :deep(.ant-empty-description) {
    color: #00f8fe;
  }
}
</style>
